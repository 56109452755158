$posleft: 0em;

.luf-quote {
  blockquote {
    position: relative;
    padding: 1.5em;
    margin-bottom: 1em;
    background-color: $white;
    //box-shadow: 0 0 0 0.625em $gray-400;
    border: 0.625em solid $gray-400;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom:-43px;
      left: $posleft;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      border-bottom: 50px solid $gray-400;
      transform: rotate(-45deg);

    }
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom:-30px;
      left: 1.9em;
      left: $posleft + 0.9;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 30px solid $white;
      transform: rotate(-45deg);
      transform-origin: top;
    }
  }

  &--author {
    display: inline-block;
    margin-left: 35%;

    @include media-breakpoint-up(sm) {
      margin-left: 25%;
    }
  }
}