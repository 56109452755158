body {
  position: relative;
  background: $white;
  width: 100vw;
  overflow-x:hidden;
  overflow-y: auto;
  transition: opacity 1000ms $ease-in-out;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/**************************************
*layout
*************************************/

//content holder main
.luf-main {
  flex: 1;
  -ms-flex: 1 auto;
}

//container default
.luf-container {
  max-width: rem-calc($container-max-width);
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(lg) {
    width: 90%;
  }
  &__full {
    width: 100%;
  }
}

.luf-subcontent {
  background-color: rgba(#e7e7e7,0.5);
}

/**************************************
* spacing
*************************************/

.luf-spacing {
  padding-top: rem-calc(60);
  padding-bottom: rem-calc(60);

  @include media-breakpoint-up(md) {
    padding-top: rem-calc(150);
    padding-bottom: rem-calc(150);
  }

  &__small {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);

    @include media-breakpoint-up(md) {
      padding-top: rem-calc(71);
      padding-bottom: rem-calc(71);
    }

    &--bottom {
      padding-bottom: rem-calc(30);
      @include media-breakpoint-up(md) {
        padding-bottom: rem-calc(71);
      }
    }
    &--top {
      padding-top: rem-calc(30);
      @include media-breakpoint-up(md) {
        padding-top: rem-calc(71);
      }
    }
  }

  &__top {
    padding-top: rem-calc(60);

    @include media-breakpoint-up(md) {
      padding-top: rem-calc(150);
    }
  }

  &__bottom {
    padding-bottom: rem-calc(60);
    @include media-breakpoint-up(md) {
      padding-bottom: rem-calc(150);
    }
  }
}

.luf-mspacing {
  margin-top: rem-calc(60);
  margin-bottom: rem-calc(60);

  @include media-breakpoint-up(md) {
    margin-top: rem-calc(120);
    margin-bottom: rem-calc(120);
  }
}

/**************************************
* trimlines
*************************************/

.luf-trimline {
  width: 100%;
  height: 1px;
  background-color: rgba($body-color, 0.1);
  margin-top: rem-calc(81);
}


/**************************************
* triangle
*************************************/

.luf-triangle-up {
  @include triangle-up(10%, $primary);
}

/**************************************
*images
*************************************/

img {
  width: 100%;
  height: auto;
}

figure {
  figcaption {
    line-height: 1;
  }
}


// svg
svg {
  width: 100%;
  height: auto;
}
svg:not(:root) {
  overflow: hidden;
}