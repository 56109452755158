select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;       /* remove default arrow */
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 18 10' xml:space='preserve'> <path fill='%23ffffff' fill-opacity='0' stroke='%237EB3D1' stroke-width='2' d='M17,1L9,9L1,1'/></svg>"); /* add custom arrow */
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-size: 1em;
  border-color:$input-border-color;
  background-color: $input-bg;
  width: 100%;
  padding: 0.6rem 2rem 0.6rem $input-padding-x !important;
  height: auto !important;
  border-radius: $input-border-radius;
}

textarea {
  width: 100%;
  background-color: $input-bg;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  padding: 4rem 1rem 1rem 1rem;
  box-shadow: none;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.luf-form {
  input[type=file] {
    width: 1px;
    visibility: hidden;
    position: absolute;
  }

  input[type=number] {
    height: 46px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  input, select {
    &.error {
      margin-bottom: 5px;
    }
  }

  .error-notice {
    color:$danger;
  }

  &--disclaimer {

    &__info {
      border-top: 5px double $gray-400;
    }
  }

  .form-check, .form-control-file {
    label {
      &.luf-file-label {
        font-size: 0.8rem;
        color: $gray-500;
        width: 100%;
      }
      &.error {
        position: relative;
      }
    }
  }

  label {
    &.required:after {
      content: " *";
    }
    &.error {
      position: absolute;
      color: $danger;
      font-size: 0.8rem;
    }
  }

  .g-recaptcha {
    margin-bottom: 15px;
  }

  .captcha-response {
    position: relative;
    height: 25px;
    margin-top: -15px;
  }
}

.luf-label--textfield {
  position: relative;
  label {
    position: absolute;
    top:0.5rem;
    left:1rem;
    color: $gray-500;

    &.required:before {
     content: "*";
   }
  }
}

.katalog-checkbox {
  .form-check-input {
    margin-left: 0px;
  }

  label {
    &.form-check-label {
      margin-left: $form-check-input-gutter;
    }
  }
}


.flash-notice {
  color: white;
  padding: 20px;

  &.success {
    background-color:$success;
  }

  &.error {
    background-color:$danger;
  }
}