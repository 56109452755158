@charset "UTF-8";
:root {
  --primary: #0095d9;
  --secondary: #353334;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #F0F0F0;
  --dark: #333333;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 540px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1140px;
  --breakpoint-xl: 1400px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(53, 51, 52, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #353334;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0095d9;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #00608d;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.875rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 540px) {
  .container {
    max-width: 570px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 828px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1052px;
  }
}
@media (min-width: 1140px) {
  .container {
    max-width: 1170px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-xs,
.col-xs-auto, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 540px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xs-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xs-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xs-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xs-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 13;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xs-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xs-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xs-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xs-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1140px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 1.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(231, 231, 231, 0.7);
  background-clip: padding-box;
  border: 1px solid rgba(231, 231, 231, 0.7);
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: rgba(231, 231, 231, 0.7);
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(1.375rem + 1px);
  padding-bottom: calc(1.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: auto;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: auto;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 768px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #353334;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4625rem 1.7375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #353334;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0095d9;
  border-color: #0095d9;
}
.btn-primary:hover {
  color: #fff;
  background-color: #007bb3;
  border-color: #0072a6;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 165, 223, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0095d9;
  border-color: #0095d9;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0072a6;
  border-color: #006999;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 165, 223, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #353334;
  border-color: #353334;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #222021;
  border-color: #1b1a1b;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 82, 82, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #353334;
  border-color: #353334;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1b1a1b;
  border-color: #151414;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 82, 82, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #F0F0F0;
  border-color: #F0F0F0;
}
.btn-light:hover {
  color: #212529;
  background-color: #dddddd;
  border-color: #d7d7d7;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #F0F0F0;
  border-color: #F0F0F0;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d7d7d7;
  border-color: #d0d0d0;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-dark:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #131313;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.btn-outline-primary {
  color: #0095d9;
  border-color: #0095d9;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0095d9;
  border-color: #0095d9;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 149, 217, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0095d9;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0095d9;
  border-color: #0095d9;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 149, 217, 0.5);
}

.btn-outline-secondary {
  color: #353334;
  border-color: #353334;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #353334;
  border-color: #353334;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 51, 52, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #353334;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #353334;
  border-color: #353334;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 51, 52, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #F0F0F0;
  border-color: #F0F0F0;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #F0F0F0;
  border-color: #F0F0F0;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #F0F0F0;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #F0F0F0;
  border-color: #F0F0F0;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}

.btn-outline-dark {
  color: #333333;
  border-color: #333333;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #333333;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0095d9;
  text-decoration: none;
}
.btn-link:hover {
  color: #00608d;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0095d9 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0072a6 !important;
}

.bg-secondary {
  background-color: #353334 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1b1a1b !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #F0F0F0 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d7d7d7 !important;
}

.bg-dark {
  background-color: #333333 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0095d9 !important;
}

.border-secondary {
  border-color: #353334 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #F0F0F0 !important;
}

.border-dark {
  border-color: #333333 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 540px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1140px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 540px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1140px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 540px) {
  .float-xs-left {
    float: left !important;
  }
  .float-xs-right {
    float: right !important;
  }
  .float-xs-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1140px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 1.875rem !important;
}

.mt-1,
.my-1 {
  margin-top: 1.875rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 1.875rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 1.875rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 1.875rem !important;
}

.m-2 {
  margin: 0.46875rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.46875rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.46875rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.46875rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.46875rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.9375rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.9375rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.9375rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.9375rem !important;
}

.m-4 {
  margin: 1.40625rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.40625rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.40625rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.40625rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.40625rem !important;
}

.m-5 {
  margin: 2.34375rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.34375rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.34375rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.34375rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.34375rem !important;
}

.m-6 {
  margin: 2.8125rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.8125rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.8125rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.8125rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.8125rem !important;
}

.m-7 {
  margin: 3.75rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3.75rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3.75rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3.75rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3.75rem !important;
}

.m-8 {
  margin: 5.625rem !important;
}

.mt-8,
.my-8 {
  margin-top: 5.625rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 5.625rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 5.625rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 5.625rem !important;
}

.m-9 {
  margin: 11.25rem !important;
}

.mt-9,
.my-9 {
  margin-top: 11.25rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 11.25rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 11.25rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 11.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 1.875rem !important;
}

.pt-1,
.py-1 {
  padding-top: 1.875rem !important;
}

.pr-1,
.px-1 {
  padding-right: 1.875rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 1.875rem !important;
}

.pl-1,
.px-1 {
  padding-left: 1.875rem !important;
}

.p-2 {
  padding: 0.46875rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.46875rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.46875rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.46875rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.46875rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.9375rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.9375rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.9375rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.9375rem !important;
}

.p-4 {
  padding: 1.40625rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.40625rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.40625rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.40625rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.40625rem !important;
}

.p-5 {
  padding: 2.34375rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.34375rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.34375rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.34375rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.34375rem !important;
}

.p-6 {
  padding: 2.8125rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.8125rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.8125rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.8125rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.8125rem !important;
}

.p-7 {
  padding: 3.75rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3.75rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3.75rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3.75rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3.75rem !important;
}

.p-8 {
  padding: 5.625rem !important;
}

.pt-8,
.py-8 {
  padding-top: 5.625rem !important;
}

.pr-8,
.px-8 {
  padding-right: 5.625rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 5.625rem !important;
}

.pl-8,
.px-8 {
  padding-left: 5.625rem !important;
}

.p-9 {
  padding: 11.25rem !important;
}

.pt-9,
.py-9 {
  padding-top: 11.25rem !important;
}

.pr-9,
.px-9 {
  padding-right: 11.25rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 11.25rem !important;
}

.pl-9,
.px-9 {
  padding-left: 11.25rem !important;
}

.m-n1 {
  margin: -1.875rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -1.875rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -1.875rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -1.875rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -1.875rem !important;
}

.m-n2 {
  margin: -0.46875rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.46875rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.46875rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.46875rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.46875rem !important;
}

.m-n3 {
  margin: -0.9375rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.9375rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.9375rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.9375rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.9375rem !important;
}

.m-n4 {
  margin: -1.40625rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.40625rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.40625rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.40625rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.40625rem !important;
}

.m-n5 {
  margin: -2.34375rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2.34375rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2.34375rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2.34375rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2.34375rem !important;
}

.m-n6 {
  margin: -2.8125rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.8125rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.8125rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.8125rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.8125rem !important;
}

.m-n7 {
  margin: -3.75rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3.75rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3.75rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3.75rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3.75rem !important;
}

.m-n8 {
  margin: -5.625rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -5.625rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -5.625rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -5.625rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -5.625rem !important;
}

.m-n9 {
  margin: -11.25rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -11.25rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -11.25rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -11.25rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -11.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 540px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 1.875rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 1.875rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 1.875rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 1.875rem !important;
  }
  .m-xs-2 {
    margin: 0.46875rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.46875rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.46875rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.46875rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.46875rem !important;
  }
  .m-xs-3 {
    margin: 0.9375rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 0.9375rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 0.9375rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 0.9375rem !important;
  }
  .m-xs-4 {
    margin: 1.40625rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.40625rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.40625rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.40625rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.40625rem !important;
  }
  .m-xs-5 {
    margin: 2.34375rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 2.34375rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 2.34375rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 2.34375rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 2.34375rem !important;
  }
  .m-xs-6 {
    margin: 2.8125rem !important;
  }
  .mt-xs-6,
  .my-xs-6 {
    margin-top: 2.8125rem !important;
  }
  .mr-xs-6,
  .mx-xs-6 {
    margin-right: 2.8125rem !important;
  }
  .mb-xs-6,
  .my-xs-6 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-xs-6,
  .mx-xs-6 {
    margin-left: 2.8125rem !important;
  }
  .m-xs-7 {
    margin: 3.75rem !important;
  }
  .mt-xs-7,
  .my-xs-7 {
    margin-top: 3.75rem !important;
  }
  .mr-xs-7,
  .mx-xs-7 {
    margin-right: 3.75rem !important;
  }
  .mb-xs-7,
  .my-xs-7 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xs-7,
  .mx-xs-7 {
    margin-left: 3.75rem !important;
  }
  .m-xs-8 {
    margin: 5.625rem !important;
  }
  .mt-xs-8,
  .my-xs-8 {
    margin-top: 5.625rem !important;
  }
  .mr-xs-8,
  .mx-xs-8 {
    margin-right: 5.625rem !important;
  }
  .mb-xs-8,
  .my-xs-8 {
    margin-bottom: 5.625rem !important;
  }
  .ml-xs-8,
  .mx-xs-8 {
    margin-left: 5.625rem !important;
  }
  .m-xs-9 {
    margin: 11.25rem !important;
  }
  .mt-xs-9,
  .my-xs-9 {
    margin-top: 11.25rem !important;
  }
  .mr-xs-9,
  .mx-xs-9 {
    margin-right: 11.25rem !important;
  }
  .mb-xs-9,
  .my-xs-9 {
    margin-bottom: 11.25rem !important;
  }
  .ml-xs-9,
  .mx-xs-9 {
    margin-left: 11.25rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 1.875rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 1.875rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 1.875rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 1.875rem !important;
  }
  .p-xs-2 {
    padding: 0.46875rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.46875rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.46875rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.46875rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.46875rem !important;
  }
  .p-xs-3 {
    padding: 0.9375rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 0.9375rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 0.9375rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 0.9375rem !important;
  }
  .p-xs-4 {
    padding: 1.40625rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.40625rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.40625rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.40625rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.40625rem !important;
  }
  .p-xs-5 {
    padding: 2.34375rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 2.34375rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 2.34375rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 2.34375rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 2.34375rem !important;
  }
  .p-xs-6 {
    padding: 2.8125rem !important;
  }
  .pt-xs-6,
  .py-xs-6 {
    padding-top: 2.8125rem !important;
  }
  .pr-xs-6,
  .px-xs-6 {
    padding-right: 2.8125rem !important;
  }
  .pb-xs-6,
  .py-xs-6 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-xs-6,
  .px-xs-6 {
    padding-left: 2.8125rem !important;
  }
  .p-xs-7 {
    padding: 3.75rem !important;
  }
  .pt-xs-7,
  .py-xs-7 {
    padding-top: 3.75rem !important;
  }
  .pr-xs-7,
  .px-xs-7 {
    padding-right: 3.75rem !important;
  }
  .pb-xs-7,
  .py-xs-7 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xs-7,
  .px-xs-7 {
    padding-left: 3.75rem !important;
  }
  .p-xs-8 {
    padding: 5.625rem !important;
  }
  .pt-xs-8,
  .py-xs-8 {
    padding-top: 5.625rem !important;
  }
  .pr-xs-8,
  .px-xs-8 {
    padding-right: 5.625rem !important;
  }
  .pb-xs-8,
  .py-xs-8 {
    padding-bottom: 5.625rem !important;
  }
  .pl-xs-8,
  .px-xs-8 {
    padding-left: 5.625rem !important;
  }
  .p-xs-9 {
    padding: 11.25rem !important;
  }
  .pt-xs-9,
  .py-xs-9 {
    padding-top: 11.25rem !important;
  }
  .pr-xs-9,
  .px-xs-9 {
    padding-right: 11.25rem !important;
  }
  .pb-xs-9,
  .py-xs-9 {
    padding-bottom: 11.25rem !important;
  }
  .pl-xs-9,
  .px-xs-9 {
    padding-left: 11.25rem !important;
  }
  .m-xs-n1 {
    margin: -1.875rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -1.875rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -1.875rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -1.875rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -1.875rem !important;
  }
  .m-xs-n2 {
    margin: -0.46875rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.46875rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.46875rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.46875rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.46875rem !important;
  }
  .m-xs-n3 {
    margin: -0.9375rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -0.9375rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -0.9375rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -0.9375rem !important;
  }
  .m-xs-n4 {
    margin: -1.40625rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.40625rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.40625rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.40625rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.40625rem !important;
  }
  .m-xs-n5 {
    margin: -2.34375rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -2.34375rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -2.34375rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -2.34375rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -2.34375rem !important;
  }
  .m-xs-n6 {
    margin: -2.8125rem !important;
  }
  .mt-xs-n6,
  .my-xs-n6 {
    margin-top: -2.8125rem !important;
  }
  .mr-xs-n6,
  .mx-xs-n6 {
    margin-right: -2.8125rem !important;
  }
  .mb-xs-n6,
  .my-xs-n6 {
    margin-bottom: -2.8125rem !important;
  }
  .ml-xs-n6,
  .mx-xs-n6 {
    margin-left: -2.8125rem !important;
  }
  .m-xs-n7 {
    margin: -3.75rem !important;
  }
  .mt-xs-n7,
  .my-xs-n7 {
    margin-top: -3.75rem !important;
  }
  .mr-xs-n7,
  .mx-xs-n7 {
    margin-right: -3.75rem !important;
  }
  .mb-xs-n7,
  .my-xs-n7 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xs-n7,
  .mx-xs-n7 {
    margin-left: -3.75rem !important;
  }
  .m-xs-n8 {
    margin: -5.625rem !important;
  }
  .mt-xs-n8,
  .my-xs-n8 {
    margin-top: -5.625rem !important;
  }
  .mr-xs-n8,
  .mx-xs-n8 {
    margin-right: -5.625rem !important;
  }
  .mb-xs-n8,
  .my-xs-n8 {
    margin-bottom: -5.625rem !important;
  }
  .ml-xs-n8,
  .mx-xs-n8 {
    margin-left: -5.625rem !important;
  }
  .m-xs-n9 {
    margin: -11.25rem !important;
  }
  .mt-xs-n9,
  .my-xs-n9 {
    margin-top: -11.25rem !important;
  }
  .mr-xs-n9,
  .mx-xs-n9 {
    margin-right: -11.25rem !important;
  }
  .mb-xs-n9,
  .my-xs-n9 {
    margin-bottom: -11.25rem !important;
  }
  .ml-xs-n9,
  .mx-xs-n9 {
    margin-left: -11.25rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 1.875rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1.875rem !important;
  }
  .m-sm-2 {
    margin: 0.46875rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.46875rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.46875rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.46875rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.46875rem !important;
  }
  .m-sm-3 {
    margin: 0.9375rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.9375rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.9375rem !important;
  }
  .m-sm-4 {
    margin: 1.40625rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.40625rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.40625rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.40625rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.40625rem !important;
  }
  .m-sm-5 {
    margin: 2.34375rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.34375rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.34375rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.34375rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.34375rem !important;
  }
  .m-sm-6 {
    margin: 2.8125rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.8125rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.8125rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.8125rem !important;
  }
  .m-sm-7 {
    margin: 3.75rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.75rem !important;
  }
  .m-sm-8 {
    margin: 5.625rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5.625rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5.625rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5.625rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5.625rem !important;
  }
  .m-sm-9 {
    margin: 11.25rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 11.25rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 11.25rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 11.25rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 11.25rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 1.875rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1.875rem !important;
  }
  .p-sm-2 {
    padding: 0.46875rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.46875rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.46875rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.46875rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.46875rem !important;
  }
  .p-sm-3 {
    padding: 0.9375rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.9375rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.9375rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.9375rem !important;
  }
  .p-sm-4 {
    padding: 1.40625rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.40625rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.40625rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.40625rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.40625rem !important;
  }
  .p-sm-5 {
    padding: 2.34375rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.34375rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.34375rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.34375rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.34375rem !important;
  }
  .p-sm-6 {
    padding: 2.8125rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.8125rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.8125rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.8125rem !important;
  }
  .p-sm-7 {
    padding: 3.75rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.75rem !important;
  }
  .p-sm-8 {
    padding: 5.625rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5.625rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5.625rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5.625rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5.625rem !important;
  }
  .p-sm-9 {
    padding: 11.25rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 11.25rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 11.25rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 11.25rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 11.25rem !important;
  }
  .m-sm-n1 {
    margin: -1.875rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1.875rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1.875rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1.875rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1.875rem !important;
  }
  .m-sm-n2 {
    margin: -0.46875rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.46875rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.46875rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.46875rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.46875rem !important;
  }
  .m-sm-n3 {
    margin: -0.9375rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.9375rem !important;
  }
  .m-sm-n4 {
    margin: -1.40625rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.40625rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.40625rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.40625rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.40625rem !important;
  }
  .m-sm-n5 {
    margin: -2.34375rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2.34375rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2.34375rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2.34375rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2.34375rem !important;
  }
  .m-sm-n6 {
    margin: -2.8125rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.8125rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.8125rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.8125rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.8125rem !important;
  }
  .m-sm-n7 {
    margin: -3.75rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3.75rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3.75rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3.75rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3.75rem !important;
  }
  .m-sm-n8 {
    margin: -5.625rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5.625rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5.625rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5.625rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5.625rem !important;
  }
  .m-sm-n9 {
    margin: -11.25rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -11.25rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -11.25rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -11.25rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -11.25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 1.875rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1.875rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1.875rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1.875rem !important;
  }
  .m-md-2 {
    margin: 0.46875rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.46875rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.46875rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.46875rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.46875rem !important;
  }
  .m-md-3 {
    margin: 0.9375rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.9375rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.9375rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.9375rem !important;
  }
  .m-md-4 {
    margin: 1.40625rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.40625rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.40625rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.40625rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.40625rem !important;
  }
  .m-md-5 {
    margin: 2.34375rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.34375rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.34375rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.34375rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.34375rem !important;
  }
  .m-md-6 {
    margin: 2.8125rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.8125rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.8125rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.8125rem !important;
  }
  .m-md-7 {
    margin: 3.75rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.75rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.75rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.75rem !important;
  }
  .m-md-8 {
    margin: 5.625rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5.625rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5.625rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5.625rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5.625rem !important;
  }
  .m-md-9 {
    margin: 11.25rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 11.25rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 11.25rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 11.25rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 11.25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 1.875rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1.875rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1.875rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1.875rem !important;
  }
  .p-md-2 {
    padding: 0.46875rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.46875rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.46875rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.46875rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.46875rem !important;
  }
  .p-md-3 {
    padding: 0.9375rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.9375rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.9375rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.9375rem !important;
  }
  .p-md-4 {
    padding: 1.40625rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.40625rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.40625rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.40625rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.40625rem !important;
  }
  .p-md-5 {
    padding: 2.34375rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.34375rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.34375rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.34375rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.34375rem !important;
  }
  .p-md-6 {
    padding: 2.8125rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.8125rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.8125rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.8125rem !important;
  }
  .p-md-7 {
    padding: 3.75rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.75rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.75rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.75rem !important;
  }
  .p-md-8 {
    padding: 5.625rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5.625rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5.625rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5.625rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5.625rem !important;
  }
  .p-md-9 {
    padding: 11.25rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 11.25rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 11.25rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 11.25rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 11.25rem !important;
  }
  .m-md-n1 {
    margin: -1.875rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1.875rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1.875rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1.875rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1.875rem !important;
  }
  .m-md-n2 {
    margin: -0.46875rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.46875rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.46875rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.46875rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.46875rem !important;
  }
  .m-md-n3 {
    margin: -0.9375rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.9375rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.9375rem !important;
  }
  .m-md-n4 {
    margin: -1.40625rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.40625rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.40625rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.40625rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.40625rem !important;
  }
  .m-md-n5 {
    margin: -2.34375rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2.34375rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2.34375rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2.34375rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2.34375rem !important;
  }
  .m-md-n6 {
    margin: -2.8125rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.8125rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.8125rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.8125rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.8125rem !important;
  }
  .m-md-n7 {
    margin: -3.75rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3.75rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3.75rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3.75rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3.75rem !important;
  }
  .m-md-n8 {
    margin: -5.625rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5.625rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5.625rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5.625rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5.625rem !important;
  }
  .m-md-n9 {
    margin: -11.25rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -11.25rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -11.25rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -11.25rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -11.25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1140px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 1.875rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1.875rem !important;
  }
  .m-lg-2 {
    margin: 0.46875rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.46875rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.46875rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.46875rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.46875rem !important;
  }
  .m-lg-3 {
    margin: 0.9375rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.9375rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.9375rem !important;
  }
  .m-lg-4 {
    margin: 1.40625rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.40625rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.40625rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.40625rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.40625rem !important;
  }
  .m-lg-5 {
    margin: 2.34375rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.34375rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.34375rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.34375rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.34375rem !important;
  }
  .m-lg-6 {
    margin: 2.8125rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.8125rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.8125rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.8125rem !important;
  }
  .m-lg-7 {
    margin: 3.75rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.75rem !important;
  }
  .m-lg-8 {
    margin: 5.625rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5.625rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5.625rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5.625rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5.625rem !important;
  }
  .m-lg-9 {
    margin: 11.25rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 11.25rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 11.25rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 11.25rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 11.25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 1.875rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1.875rem !important;
  }
  .p-lg-2 {
    padding: 0.46875rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.46875rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.46875rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.46875rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.46875rem !important;
  }
  .p-lg-3 {
    padding: 0.9375rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.9375rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.9375rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.9375rem !important;
  }
  .p-lg-4 {
    padding: 1.40625rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.40625rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.40625rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.40625rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.40625rem !important;
  }
  .p-lg-5 {
    padding: 2.34375rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.34375rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.34375rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.34375rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.34375rem !important;
  }
  .p-lg-6 {
    padding: 2.8125rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.8125rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.8125rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.8125rem !important;
  }
  .p-lg-7 {
    padding: 3.75rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.75rem !important;
  }
  .p-lg-8 {
    padding: 5.625rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5.625rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5.625rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5.625rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5.625rem !important;
  }
  .p-lg-9 {
    padding: 11.25rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 11.25rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 11.25rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 11.25rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 11.25rem !important;
  }
  .m-lg-n1 {
    margin: -1.875rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1.875rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1.875rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1.875rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1.875rem !important;
  }
  .m-lg-n2 {
    margin: -0.46875rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.46875rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.46875rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.46875rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.46875rem !important;
  }
  .m-lg-n3 {
    margin: -0.9375rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.9375rem !important;
  }
  .m-lg-n4 {
    margin: -1.40625rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.40625rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.40625rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.40625rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.40625rem !important;
  }
  .m-lg-n5 {
    margin: -2.34375rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2.34375rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2.34375rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2.34375rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2.34375rem !important;
  }
  .m-lg-n6 {
    margin: -2.8125rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.8125rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.8125rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.8125rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.8125rem !important;
  }
  .m-lg-n7 {
    margin: -3.75rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3.75rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3.75rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3.75rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3.75rem !important;
  }
  .m-lg-n8 {
    margin: -5.625rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5.625rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5.625rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5.625rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5.625rem !important;
  }
  .m-lg-n9 {
    margin: -11.25rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -11.25rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -11.25rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -11.25rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -11.25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 1.875rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1.875rem !important;
  }
  .m-xl-2 {
    margin: 0.46875rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.46875rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.46875rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.46875rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.46875rem !important;
  }
  .m-xl-3 {
    margin: 0.9375rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.9375rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.9375rem !important;
  }
  .m-xl-4 {
    margin: 1.40625rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.40625rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.40625rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.40625rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.40625rem !important;
  }
  .m-xl-5 {
    margin: 2.34375rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.34375rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.34375rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.34375rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.34375rem !important;
  }
  .m-xl-6 {
    margin: 2.8125rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.8125rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.8125rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.8125rem !important;
  }
  .m-xl-7 {
    margin: 3.75rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.75rem !important;
  }
  .m-xl-8 {
    margin: 5.625rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5.625rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5.625rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5.625rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5.625rem !important;
  }
  .m-xl-9 {
    margin: 11.25rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 11.25rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 11.25rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 11.25rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 11.25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 1.875rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1.875rem !important;
  }
  .p-xl-2 {
    padding: 0.46875rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.46875rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.46875rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.46875rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.46875rem !important;
  }
  .p-xl-3 {
    padding: 0.9375rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.9375rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.9375rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.9375rem !important;
  }
  .p-xl-4 {
    padding: 1.40625rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.40625rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.40625rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.40625rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.40625rem !important;
  }
  .p-xl-5 {
    padding: 2.34375rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.34375rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.34375rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.34375rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.34375rem !important;
  }
  .p-xl-6 {
    padding: 2.8125rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.8125rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.8125rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.8125rem !important;
  }
  .p-xl-7 {
    padding: 3.75rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.75rem !important;
  }
  .p-xl-8 {
    padding: 5.625rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5.625rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5.625rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5.625rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5.625rem !important;
  }
  .p-xl-9 {
    padding: 11.25rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 11.25rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 11.25rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 11.25rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 11.25rem !important;
  }
  .m-xl-n1 {
    margin: -1.875rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1.875rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1.875rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1.875rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1.875rem !important;
  }
  .m-xl-n2 {
    margin: -0.46875rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.46875rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.46875rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.46875rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.46875rem !important;
  }
  .m-xl-n3 {
    margin: -0.9375rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.9375rem !important;
  }
  .m-xl-n4 {
    margin: -1.40625rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.40625rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.40625rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.40625rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.40625rem !important;
  }
  .m-xl-n5 {
    margin: -2.34375rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2.34375rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2.34375rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2.34375rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2.34375rem !important;
  }
  .m-xl-n6 {
    margin: -2.8125rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.8125rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.8125rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.8125rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.8125rem !important;
  }
  .m-xl-n7 {
    margin: -3.75rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3.75rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3.75rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3.75rem !important;
  }
  .m-xl-n8 {
    margin: -5.625rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5.625rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5.625rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5.625rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5.625rem !important;
  }
  .m-xl-n9 {
    margin: -11.25rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -11.25rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -11.25rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -11.25rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -11.25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 540px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1140px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0095d9 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #00608d !important;
}

.text-secondary {
  color: #353334 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #0e0d0e !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #F0F0F0 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cacaca !important;
}

.text-dark {
  color: #333333 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0d !important;
}

.text-body {
  color: #353334 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(53, 51, 52, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #979797;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #353334;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e7e7e7 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* fluid typo */
body {
  position: relative;
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 1000ms cubic-bezier(0.86, 0, 0.07, 1);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/**************************************
*layout
*************************************/
.luf-main {
  flex: 1;
  -ms-flex: 1 auto;
}

.luf-container {
  max-width: 71.25rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1399.98px) {
  .luf-container {
    width: 90%;
  }
}
.luf-container__full {
  width: 100%;
}

.luf-subcontent {
  background-color: rgba(231, 231, 231, 0.5);
}

/**************************************
* spacing
*************************************/
.luf-spacing {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
@media (min-width: 992px) {
  .luf-spacing {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
}
.luf-spacing__small {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
@media (min-width: 992px) {
  .luf-spacing__small {
    padding-top: 4.4375rem;
    padding-bottom: 4.4375rem;
  }
}
.luf-spacing__small--bottom {
  padding-bottom: 1.875rem;
}
@media (min-width: 992px) {
  .luf-spacing__small--bottom {
    padding-bottom: 4.4375rem;
  }
}
.luf-spacing__small--top {
  padding-top: 1.875rem;
}
@media (min-width: 992px) {
  .luf-spacing__small--top {
    padding-top: 4.4375rem;
  }
}
.luf-spacing__top {
  padding-top: 3.75rem;
}
@media (min-width: 992px) {
  .luf-spacing__top {
    padding-top: 9.375rem;
  }
}
.luf-spacing__bottom {
  padding-bottom: 3.75rem;
}
@media (min-width: 992px) {
  .luf-spacing__bottom {
    padding-bottom: 9.375rem;
  }
}

.luf-mspacing {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}
@media (min-width: 992px) {
  .luf-mspacing {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
}

/**************************************
* trimlines
*************************************/
.luf-trimline {
  width: 100%;
  height: 1px;
  background-color: rgba(53, 51, 52, 0.1);
  margin-top: 5.0625rem;
}

/**************************************
* triangle
*************************************/
.luf-triangle-up {
  width: 20%;
  height: 0;
  padding-left: 10%;
  padding-bottom: 10%;
  overflow: hidden;
}
.luf-triangle-up:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: -500px;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-bottom: 500px solid #0095d9;
}

/**************************************
*images
*************************************/
img {
  width: 100%;
  height: auto;
}

figure figcaption {
  line-height: 1;
}

svg {
  width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

.luf-img {
  position: relative;
  overflow: hidden;
}
.luf-img--holder {
  display: block;
  background-color: #D8D8D8;
}
.luf-img img {
  position: absolute;
  top: 0;
  left: 0;
}
.luf-img figure {
  margin: 0;
}
.luf-img-copyright {
  position: relative;
  padding: 0.25rem 0 0 0.375rem;
  background-color: #fff;
  color: #484848;
  font-size: 0.625rem;
  border-top-left-radius: 4px;
  z-index: 1;
}
@media (min-width: 1140px) {
  .luf-img-copyright {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.luf-img-copyright.is-small {
  padding: 0.125rem 0 0 0.25rem;
}

.luf-lazy .luf-lazy--item {
  opacity: 0;
  transition: all 500ms ease-out;
  transition-delay: 50ms;
  clip-path: polygon(0% 100%, 0% 0%, 0% 0%, 0% 100%);
}
.luf-lazy .loaded.luf-lazy--item {
  opacity: 1;
  clip-path: polygon(101% 100%, 101% 0%, 0% 0%, 0% 100%);
}
.luf-lazy .luf-lazy--bg {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
.luf-lazy .luf-lazy--bg.loaded {
  opacity: 1;
  height: 100%;
}

html {
  font-size: 100%;
}

a {
  transition-duration: 0.3s;
}
a:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 149, 217, 0.5);
  border-radius: 2px;
}

p {
  line-height: 1.8;
}

blockquote {
  display: inline-block;
  position: relative;
  margin: 0;
}
blockquote p:first-child {
  font-style: italic;
}
blockquote p:first-child:before {
  top: 15px;
  left: 20px;
  content: "“";
}
blockquote p:first-child:after {
  bottom: -10px;
  right: 20px;
  content: "„";
}

.luf-ul-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.luf-toc {
  margin: 0;
  padding: 0 !important;
  list-style: none;
}
.luf-toc li {
  display: flex;
}
.luf-toc li.luf-toc--sep {
  margin-top: 1rem;
}
.luf-toc li:not(.luf-toc--sep):after {
  content: "";
  border-bottom: 1px dotted;
  flex-grow: 1;
  order: 2;
}
.luf-toc--title {
  order: 1;
}
.luf-toc--details {
  order: 3;
}

/*****************************
- global font classes
 ******************************/
.luf-ft-s5 {
  font-size: 3.75rem;
  font-size: 32px;
}
@media (min-width: 320px) {
  .luf-ft-s5 {
    font-size: calc(32px + 28 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s5 {
    font-size: 60px;
  }
}

.luf-ft-s4 {
  font-size: 2.5rem;
  font-size: 24px;
}
@media (min-width: 320px) {
  .luf-ft-s4 {
    font-size: calc(24px + 16 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s4 {
    font-size: 40px;
  }
}

.luf-ft-s3 {
  font-size: 1.875rem;
  font-size: 20px;
}
@media (min-width: 320px) {
  .luf-ft-s3 {
    font-size: calc(20px + 10 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s3 {
    font-size: 30px;
  }
}

.luf-ft-s2 {
  font-size: 1.5rem;
  font-size: 19.2px;
}
@media (min-width: 320px) {
  .luf-ft-s2 {
    font-size: calc(19.2px + 4.8 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s2 {
    font-size: 24px;
  }
}

.luf-ft-s1 {
  font-size: 1.25rem;
}

.luf-ft-sn {
  font-size: 1rem;
}

.luf-ft-s-1, small {
  font-size: 0.875rem;
}

.luf-ft-s-2, small {
  font-size: 0.75rem;
}

.luf-ft-s-3 {
  font-size: 0.625rem;
}

.luf-ft-w-900 {
  font-weight: 900;
}

.luf-ft-w-700, .luf-link--colored, .luf-link--bold, strong {
  font-weight: 700;
}

.luf-ft-w-500 {
  font-weight: 500;
}

.luf-ft-w-400 {
  font-weight: 400;
}

.luf-ft-w-300 {
  font-weight: 300;
}

.luf-ft-w-100 {
  font-weight: 100;
}

.luf-ft-lh-small {
  line-height: 1.1;
}

.luf-ft-lh-medium {
  line-height: 1.5;
  vertical-align: middle;
}

.luf-handwriting {
  font-family: "HandTest", "Roboto", sans-serif;
}

.luf-ft-t-uc {
  text-transform: uppercase;
}

.luf-ft-t-it {
  font-style: italic;
}

.luf-hide {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.luf-visible {
  position: relative !important;
  clip: auto;
  overflow: visible;
  width: auto;
  height: auto;
}

@media (min-width: 992px) {
  .luf-col-two {
    column-count: 2;
    column-gap: 3rem;
  }
}

.luf-ft-white {
  color: #fff;
}

.luf-ft-black {
  color: #353334;
}

.luf-ft-gray {
  color: #979797;
}

.luf-link {
  position: relative;
  color: #353334;
  box-shadow: 0 2px 0 #0095d9;
}
.luf-link:hover, .luf-link:focus {
  color: #0095d9;
}
.luf-link--colored {
  color: #0095d9;
}
.luf-link--colored:hover, .luf-link--colored:focus {
  color: #0072a6;
}

button:focus, button:active {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 149, 217, 0.5);
  border-radius: 2px;
}

.luf-quote blockquote {
  position: relative;
  padding: 1.5em;
  margin-bottom: 1em;
  background-color: #fff;
  border: 0.625em solid #D8D8D8;
}
.luf-quote blockquote:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -43px;
  left: 0em;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid #D8D8D8;
  transform: rotate(-45deg);
}
.luf-quote blockquote:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -30px;
  left: 1.9em;
  left: 0.9em;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #fff;
  transform: rotate(-45deg);
  transform-origin: top;
}
.luf-quote--author {
  display: inline-block;
  margin-left: 35%;
}
@media (min-width: 768px) {
  .luf-quote--author {
    margin-left: 25%;
  }
}

.icon-flipped {
  display: inline-block;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.luf-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: #353334;
  fill-opacity: 1;
  vertical-align: text-top;
  transition-duration: 0.3s;
  margin-right: 0.25em;
}
.luf-icon__inline {
  margin-right: 0.4em;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* remove default arrow */
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 18 10' xml:space='preserve'> <path fill='%23ffffff' fill-opacity='0' stroke='%237EB3D1' stroke-width='2' d='M17,1L9,9L1,1'/></svg>"); /* add custom arrow */
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-size: 1em;
  border-color: rgba(231, 231, 231, 0.7);
  background-color: rgba(231, 231, 231, 0.7);
  width: 100%;
  padding: 0.6rem 2rem 0.6rem 1rem !important;
  height: auto !important;
  border-radius: 2px;
}

textarea {
  width: 100%;
  background-color: rgba(231, 231, 231, 0.7);
  border: 1px solid rgba(231, 231, 231, 0.7);
  border-radius: 2px;
  padding: 4rem 1rem 1rem 1rem;
  box-shadow: none;
}
textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.luf-form input[type=file] {
  width: 1px;
  visibility: hidden;
  position: absolute;
}
.luf-form input[type=number] {
  height: 46px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.luf-form input.error, .luf-form select.error {
  margin-bottom: 5px;
}
.luf-form .error-notice {
  color: #dc3545;
}
.luf-form--disclaimer__info {
  border-top: 5px double #D8D8D8;
}
.luf-form .form-check label.luf-file-label, .luf-form .form-control-file label.luf-file-label {
  font-size: 0.8rem;
  color: #979797;
  width: 100%;
}
.luf-form .form-check label.error, .luf-form .form-control-file label.error {
  position: relative;
}
.luf-form label.required:after {
  content: " *";
}
.luf-form label.error {
  position: absolute;
  color: #dc3545;
  font-size: 0.8rem;
}
.luf-form .g-recaptcha {
  margin-bottom: 15px;
}
.luf-form .captcha-response {
  position: relative;
  height: 25px;
  margin-top: -15px;
}

.luf-label--textfield {
  position: relative;
}
.luf-label--textfield label {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  color: #979797;
}
.luf-label--textfield label.required:before {
  content: "*";
}

.katalog-checkbox .form-check-input {
  margin-left: 0px;
}
.katalog-checkbox label.form-check-label {
  margin-left: 1.25rem;
}

.flash-notice {
  color: white;
  padding: 20px;
}
.flash-notice.success {
  background-color: #28a745;
}
.flash-notice.error {
  background-color: #dc3545;
}

.luf-floatlabel {
  position: relative;
}
.luf-floatlabel.is-focused .luf-floatlabel--label, .luf-floatlabel.has-value .luf-floatlabel--label {
  left: 0.3rem;
  transform: translateY(-100%) scale(0.6);
}
.luf-floatlabel--label {
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 1.4375rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #979797;
  transform-origin: 0 0;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(-50%);
}

/**************************************
*backgrounds
*************************************/
.luf-bg--resp {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.luf-bg--abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.luf-bg--bgc {
  background-color: #D8D8D8;
}

.luf-menubtn {
  display: block;
  position: absolute;
  right: 1rem;
  top: 50%;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  transform: translateY(-50%);
  z-index: 999;
  font-weight: 600;
  cursor: pointer;
}
@media (min-width: 768px) {
  .luf-menubtn {
    display: none;
  }
}

.luf-navicon {
  position: relative;
  width: 1.875rem;
  height: 1.25rem;
  line-height: 1.875rem;
  vertical-align: middle;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.luf-navicon span {
  display: block;
  position: absolute;
  height: 0.25rem;
  width: 100%;
  background: #fff;
  border-radius: 0.1875rem;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.luf-navicon span:nth-child(1) {
  top: 0px;
}
.luf-navicon span:nth-child(2), .luf-navicon span:nth-child(3) {
  top: 8px;
}
.luf-navicon span:nth-child(4) {
  top: 1rem;
}
.luf-navicon.open span {
  background: #0095d9;
}
.luf-navicon.open span:nth-child(1) {
  top: 1.125rem;
  width: 0%;
  left: 50%;
}
.luf-navicon.open span:nth-child(2) {
  transform: rotate(45deg);
}
.luf-navicon.open span:nth-child(3) {
  transform: rotate(-45deg);
}
.luf-navicon.open span:nth-child(4) {
  top: 1.125rem;
  width: 0%;
  left: 50%;
}

.luf-navtxt {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.luf-mainnav {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0.3rem;
}
@media (min-width: 768px) {
  .luf-mainnav {
    height: auto;
    overflow: visible;
    padding-left: 0;
  }
}
.luf-mainnav--wrapper {
  position: fixed;
  width: 90%;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
  padding: 3.75rem;
  padding-right: 1rem;
  padding-top: 7rem;
  transform: translateX(100%);
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.luf-mainnav--wrapper.open {
  transform: translateX(0%);
}
@media (min-width: 768px) {
  .luf-mainnav--wrapper {
    position: static;
    width: auto;
    height: auto;
    top: auto;
    height: auto;
    background-color: transparent;
    padding: 0;
    transform: none;
  }
}
.luf-mainnav--item {
  position: relative;
  padding: 0.9375rem 0;
}
@media (min-width: 768px) {
  .luf-mainnav--item {
    position: static;
    display: inline-block;
    padding: 1.8125rem 0;
    margin-right: 1.25rem;
  }
}
@media (min-width: 992px) {
  .luf-mainnav--item {
    margin-right: 2.5625rem;
  }
}
.luf-mainnav--item:last-child {
  margin-right: 0;
}
.luf-mainnav--item.active > a {
  box-shadow: 0 3px 0 #fff;
}
@media (min-width: 768px) {
  .luf-mainnav--item > a {
    color: #fff;
  }
}
.luf-mainnav--item__indicator {
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  height: 1.2rem;
  width: 1.2rem;
  overflow: hidden;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
@media (min-width: 768px) {
  .luf-mainnav--item__indicator {
    display: none;
    pointer-events: none;
  }
}
.luf-mainnav--item__arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 50%;
  margin: auto;
  padding: 0;
  width: 1rem;
  height: 1rem;
  transform: rotate(0deg) translateX(50%) translateY(-50%);
  top: 25%;
}
.luf-mainnav--item__arrow:before, .luf-mainnav--item__arrow:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  height: 78%;
  width: 0.1875rem;
  background: #0095d9;
  border-radius: 3px;
}
.luf-mainnav--item__arrow:before {
  right: 20%;
  transform: rotate(45deg);
  transform-origin: right;
}
.luf-mainnav--item__arrow:after {
  left: 20%;
  transform: rotate(-45deg);
  transform-origin: left;
}
.luf-mainnav--item__arrow.open:before {
  transform: rotate(-45deg);
}
.luf-mainnav--item__arrow.open:after {
  transform: rotate(45deg);
}
.luf-mainnav--item__arrow:before, .luf-mainnav--item__arrow:after {
  transition-duration: 0.3s;
}

.luf-header .luf-has-megamenu .luf-header .luf-has-megamenu > a:focus + .luf-megamenu, .luf-header .luf-has-megamenu .luf-header .luf-has-megamenu > a:focus-within + .luf-megamenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  pointer-events: auto;
  transition-delay: 0s;
}
.luf-header .luf-has-megamenu:hover > .luf-megamenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  pointer-events: auto;
  transition-delay: 0s;
}
.luf-header .luf-has-megamenu .luf-megamenu {
  display: none;
  padding-top: 1rem;
}
@media (min-width: 768px) {
  .luf-header .luf-has-megamenu .luf-megamenu {
    display: flex;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    padding: 2.5rem;
    background: #fff;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
    z-index: 254;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transform: translateY(4rem);
  }
}
@media (min-width: 768px) {
  .luf-header .luf-has-megamenu .luf-megamenu--indicator {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-100%);
    width: 2.5rem;
    height: 0;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    overflow: hidden;
  }
  .luf-header .luf-has-megamenu .luf-megamenu--indicator:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-left: -500px;
    border-left: 500px solid transparent;
    border-right: 500px solid transparent;
    border-bottom: 500px solid #fff;
  }
}
.luf-header .luf-has-megamenu .luf-megamenu p {
  margin-bottom: 0.625rem;
}
.luf-header .luf-has-megamenu .luf-megamenu--card {
  padding-right: 1.25rem !important;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .luf-header .luf-has-megamenu .luf-megamenu--card {
    margin-bottom: 3.75rem;
    padding-right: 2.5rem !important;
  }
}
.luf-header .luf-has-megamenu .luf-megamenu--card a {
  display: block;
  color: #353334;
}
.luf-header .luf-has-megamenu .luf-megamenu--card p {
  line-height: 1.75;
}
.luf-header .luf-has-megamenu .luf-megamenu--card__header {
  position: relative;
  margin-bottom: 15px;
}
.luf-header .luf-has-megamenu .luf-megamenu--card__header--info {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
}
.luf-header .luf-has-megamenu .luf-megamenu--card__header--info .luf-tag--category {
  font-size: 0.75rem;
  margin: 0;
  padding: 0.5625rem 0.625rem;
}
@media (min-width: 768px) {
  .luf-header .luf-has-megamenu .luf-megamenu--card__header--info .luf-tag--category {
    font-size: 1rem;
  }
}
.luf-header .luf-has-megamenu .luf-megamenu--card__cta {
  color: #0095d9;
}
@media (min-width: 768px) {
  .luf-header .luf-has-megamenu .luf-megamenu--sideitems {
    height: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(53, 51, 52, 0.2);
    padding-top: 30px;
  }
}
@media (min-width: 1140px) {
  .luf-header .luf-has-megamenu .luf-megamenu--sideitems {
    padding-bottom: 3.75rem;
    padding-top: 0;
    border-top: 0;
    border-left: 1px solid rgba(53, 51, 52, 0.2);
    flex-flow: column;
  }
}
.luf-header .luf-has-megamenu .luf-megamenu--sideitem {
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .luf-header .luf-has-megamenu .luf-megamenu--sideitem {
    padding-right: 30px;
  }
}
@media (min-width: 1140px) {
  .luf-header .luf-has-megamenu .luf-megamenu--sideitem {
    padding-left: 30px;
    padding-right: 0;
  }
}
.luf-header .luf-has-megamenu .luf-megamenu--sideitem:last-child {
  padding-bottom: 0;
}
@media (min-width: 1140px) {
  .luf-header .luf-has-megamenu .luf-megamenu--sideitem:last-child {
    padding-top: 3rem;
    border-top: 1px solid rgba(53, 51, 52, 0.2);
  }
}
@media (min-width: 1140px) {
  .luf-header .luf-has-megamenu .luf-megamenu--sideitem:last-child .luf-icon {
    font-size: 2.8125rem;
  }
}
.luf-header .luf-has-megamenu .luf-megamenu--sideitem--header {
  display: flex;
  align-items: center;
}
.luf-header .luf-has-megamenu .luf-megamenu--sideitem--title {
  margin: 0;
}
.luf-header .luf-has-megamenu .luf-megamenu--sideitem .luf-icon {
  font-size: 2rem;
  margin-right: 1rem;
}

.luf-header .luf-has-menu {
  position: relative;
}
.luf-header .luf-has-menu .luf-header .luf-has-menu > a {
  color: #0095d9;
}
.luf-header .luf-has-menu .luf-header .luf-has-menu > a:focus + .luf-submenu, .luf-header .luf-has-menu .luf-header .luf-has-menu > a:focus-within + .luf-submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  pointer-events: auto;
  transition-delay: 0s;
}
.luf-header .luf-has-menu:hover .luf-submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  pointer-events: auto;
  transition-delay: 0s;
}
.luf-header .luf-has-menu .luf-submenu {
  display: none;
  font-size: 1rem;
  margin-left: 1rem;
  padding-top: 0.5rem;
}
@media (min-width: 768px) {
  .luf-header .luf-has-menu .luf-submenu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    height: auto;
    width: auto;
    padding-top: 0;
    margin-left: 0;
    font-size: 1.25rem;
    min-width: 15rem;
    box-shadow: 0 10px 80px rgba(0, 0, 0, 0.3);
    z-index: 254;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transform: translateY(4rem);
  }
}
@media (min-width: 768px) {
  .luf-header .luf-has-menu .luf-submenu--indicator {
    position: absolute;
    top: 0;
    left: 2rem;
    transform: translateY(-100%);
    width: 2.5rem;
    height: 0;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    overflow: hidden;
  }
  .luf-header .luf-has-menu .luf-submenu--indicator:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-left: -500px;
    border-left: 500px solid transparent;
    border-right: 500px solid transparent;
    border-bottom: 500px solid #fff;
  }
}
.luf-header .luf-has-menu .luf-submenu li {
  position: relative;
  background-color: white;
  padding: 0.5rem;
  width: 100%;
}
@media (min-width: 768px) {
  .luf-header .luf-has-menu .luf-submenu li {
    padding: 1.4375rem 1.4375rem;
    width: 120%;
  }
}
.luf-header .luf-has-menu .luf-submenu li.active a {
  color: #0095d9;
}
.luf-header .luf-has-menu .luf-submenu .sub-menu {
  left: 100%;
  z-index: 9;
}

.luf-europe .luf-searchbox--wrapper {
  background-color: #046ab0;
}
.luf-europe .luf-searchbox--wrapper .btn-primary {
  background-color: #022e4c;
  border-color: #022e4c;
}
.luf-europe .luf-tag--category {
  background-color: #046ab0;
}
.luf-europe .luf-link {
  box-shadow: 0 2px 0 #046ab0;
}
.luf-active .luf-searchbox--wrapper {
  background-color: #b8a0c4;
}
.luf-active .luf-searchbox--wrapper .btn-primary {
  background-color: #89619d;
  border-color: #89619d;
}
.luf-active .luf-tag--category {
  background-color: #b8a0c4;
}
.luf-active .luf-link {
  box-shadow: 0 2px 0 #b8a0c4;
}
.luf-music .luf-searchbox--wrapper {
  background-color: #fbc60e;
}
.luf-music .luf-searchbox--wrapper .btn-primary {
  background-color: #a07d03;
  border-color: #a07d03;
}
.luf-music .luf-tag--category {
  background-color: #fbc60e;
  color: #353334;
}
.luf-music .luf-link {
  box-shadow: 0 2px 0 #fbc60e;
}
.luf-hiking .luf-searchbox--wrapper {
  background-color: #93b13d;
}
.luf-hiking .luf-searchbox--wrapper .btn-primary {
  background-color: #546523;
  border-color: #546523;
}
.luf-hiking .luf-tag--category {
  background-color: #93b13d;
}
.luf-hiking .luf-link {
  box-shadow: 0 2px 0 #93b13d;
}
.luf-cycling .luf-searchbox--wrapper {
  background-color: #d02128;
}
.luf-cycling .luf-searchbox--wrapper .btn-primary {
  background-color: #781317;
  border-color: #781317;
}
.luf-cycling .luf-tag--category {
  background-color: #d02128;
}
.luf-cycling .luf-link {
  box-shadow: 0 2px 0 #d02128;
}
.luf-day .luf-searchbox--wrapper {
  background-color: #607d8b;
}
.luf-day .luf-searchbox--wrapper .btn-primary {
  background-color: #36474f;
  border-color: #36474f;
}
.luf-day .luf-tag--category {
  background-color: #607d8b;
}
.luf-day .luf-link {
  box-shadow: 0 2px 0 #607d8b;
}

.luf-header {
  position: relative;
}
.luf-header--big {
  height: 85vh;
  max-height: 43.75rem;
}
.luf-header--home {
  height: 85vh;
  max-height: none;
}
.luf-header .luf-pagetitle {
  position: absolute;
  bottom: 2.6875rem;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 5;
}
@media (min-width: 768px) {
  .luf-header .luf-pagetitle {
    bottom: 2.9375rem;
  }
}
.luf-header .luf-pagetitle.luf-handwriting {
  transform: rotate(-2deg);
  transform-origin: left;
  font-size: 32px;
}
@media (min-width: 320px) {
  .luf-header .luf-pagetitle.luf-handwriting {
    font-size: calc(32px + 72 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-header .luf-pagetitle.luf-handwriting {
    font-size: 104px;
  }
}
.luf-header .luf-pagetitle.en-title {
  top: 250px;
}
@media (min-width: 768px) {
  .luf-header .luf-pagetitle.en-title {
    top: 15.625rem;
  }
}
.luf-header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.luf-header > a {
  color: #353334;
}
.luf-header > a:hover {
  color: #0095d9;
}
.luf-header > a:hover .luf-icon {
  fill: #0095d9;
}
.luf-header .luf-header--top {
  position: relative;
  padding: 0.6875rem 0;
  z-index: 9;
  background-color: #fff;
}
.luf-header .luf-header--top__inner {
  display: flex;
  justify-content: space-between;
}
.luf-header .luf-header--top .luf-header--navitem {
  display: inline-block;
  margin-right: 1.5rem;
}
@media (min-width: 768px) {
  .luf-header .luf-header--top .luf-header--navitem {
    margin-right: 1.6875rem;
  }
}
.luf-header .luf-header--top .luf-header--navitem__text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}
@media (min-width: 768px) {
  .luf-header .luf-header--top .luf-header--navitem__text {
    position: relative !important;
    clip: auto;
    overflow: visible;
    width: auto;
    height: auto;
  }
}
.luf-header .luf-header--top .luf-header--nav__top:nth-child(2) .luf-header--navitem {
  margin-right: 0.8125rem;
}
.luf-header .luf-header--top img {
  width: 1.5em;
  height: auto;
}
.luf-header .luf-header--main {
  position: relative;
}
.luf-header--homelink {
  position: relative;
  z-index: 99;
  display: block;
  width: 60%;
}
@media (min-width: 768px) {
  .luf-header--homelink {
    width: 30%;
  }
}
@media (min-width: 1140px) {
  .luf-header--homelink {
    width: 50%;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .luf-header--homelink {
    width: 50%;
  }
}
.luf-header .luf-header--logo {
  width: 100%;
  padding: 1rem 0;
}
@media (min-width: 1400px) {
  .luf-header .luf-header--logo {
    padding: 0;
  }
}
.luf-header .luf-searchbox {
  position: relative;
  z-index: 9;
}
@media (min-width: 540px) {
  .luf-header .luf-header--nav:first-child {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .luf-header .luf-header--nav:first-child {
    column-count: 1;
  }
}
.luf-header .luf-header--navitem .luf-icon {
  width: 1.5em;
  height: 1.1875em;
}
.luf-header .luf-header--navitem .luf-icon--fb {
  height: 1.5em;
  fill: #046ab0;
}
.luf-header .luf-gradient::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 140px;
  width: 100%;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0.4), rgba(0, 0, 0, 0));
  z-index: 1;
}

.luf-searchbox input, .luf-searchbox textarea, .luf-searchbox select {
  background-color: #fff;
  border-color: #fff;
}
.luf-searchbox select {
  min-width: 145px;
  padding-right: 10px !important;
}
@media (min-width: 1140px) {
  .luf-searchbox select {
    min-width: 0px;
    padding-right: 2rem !important;
  }
}
.luf-searchbox--wrapper {
  position: relative;
  background-color: #0095d9;
  padding: 1.25rem;
}
.luf-searchbox .form-group {
  margin-right: 0.25rem;
  margin-bottom: 0;
  flex-grow: 1;
  flex-basis: 0;
}
.luf-searchbox .form-group:last-child {
  flex-grow: 0;
}
.luf-searchbox .form-group .luf-icon__inline {
  margin-right: 0.2em;
  fill: currentColor;
}
.luf-searchbox .form-control {
  padding: 0.5625rem 0.6375rem;
  height: 3.125rem !important;
}
@media (min-width: 992px) {
  .luf-searchbox .form-control {
    padding: 0.5625rem 1rem;
  }
}
.luf-searchbox .luf-floatlabel--label {
  left: 0.6375rem;
}
@media (min-width: 992px) {
  .luf-searchbox .luf-floatlabel--label {
    left: 1rem;
  }
}
.luf-searchbox .luf-searchbox--title {
  position: relative;
  display: inline-block;
  padding-right: 1.5625rem;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 0;
  cursor: pointer;
}
@media (min-width: 992px) {
  .luf-searchbox .luf-searchbox--title {
    line-height: 3.125rem;
    float: left;
    max-width: 20%;
    cursor: auto;
    pointer-events: none;
  }
}
.luf-searchbox .luf-searchbox--title .luf-icon {
  fill: #fff;
  margin-right: 0.1em;
}
@media (min-width: 992px) {
  .luf-searchbox .luf-searchbox--title .luf-icon {
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 0.5em;
  }
}
.luf-searchbox .luf-searchbox--status {
  position: absolute;
  top: 0.3rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
}
@media (min-width: 992px) {
  .luf-searchbox .luf-searchbox--status {
    display: none;
  }
}
.luf-searchbox .luf-searchbox--status__indicator {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 50%;
  margin: auto;
  padding: 0;
  width: 1rem;
  height: 1rem;
  transform: rotate(0deg) translateX(50%) translateY(-50%);
  transform-origin: center;
  top: 0%;
}
.luf-searchbox .luf-searchbox--status__indicator:before, .luf-searchbox .luf-searchbox--status__indicator:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  height: 78%;
  width: 0.125rem;
  background: #fff;
  border-radius: 3px;
}
.luf-searchbox .luf-searchbox--status__indicator:before {
  right: 21%;
  transform: rotate(45deg);
  transform-origin: right;
}
.luf-searchbox .luf-searchbox--status__indicator:after {
  left: 22%;
  transform: rotate(-45deg);
  transform-origin: left;
}
.luf-searchbox .luf-searchbox--status__indicator.open:before {
  transform: rotate(-45deg);
}
.luf-searchbox .luf-searchbox--status__indicator.open:after {
  transform: rotate(45deg);
}
.luf-searchbox .luf-searchbox--status__indicator:before, .luf-searchbox .luf-searchbox--status__indicator:after {
  transition-duration: 0.3s;
}
.luf-searchbox .luf-searchbox--content {
  width: 100%;
  display: none;
  padding-top: 2rem;
}
@media (min-width: 992px) {
  .luf-searchbox .luf-searchbox--content {
    display: block !important;
    float: left;
    width: 80%;
    padding-top: 0;
  }
}
.luf-searchbox .luf-searchbox--content__inside {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .luf-searchbox .luf-searchbox--content__inside {
    flex-wrap: nowrap;
  }
}
.luf-searchbox .luf-searchbox--content__inside .form-group {
  flex: 1 1 100%;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .luf-searchbox .luf-searchbox--content__inside .form-group {
    flex: 1 1 auto;
    margin-bottom: 0;
  }
}
.luf-searchbox .btn-submit {
  display: block;
  background-color: #046ab0;
  border-color: #046ab0;
  font-size: 1.25rem;
  line-height: 33px;
}
.luf-searchbox .btn-submit:focus {
  outline: 1px solid black;
}

.luf-breadcrumb {
  padding: 1rem 0;
  font-weight: 500;
}
.luf-breadcrumb .luf-breadcrumb--menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.luf-breadcrumb .luf-breadcrumb--menu li {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  padding-right: 1rem;
  line-height: 0.7;
}
.luf-breadcrumb .luf-breadcrumb--menu li:not(:last-child):after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  margin-left: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 4 7'%3E%3Cg%3E%3Cg%3E%3Cpath fill='none' stroke='%23979797' stroke-miterlimit='50' d='M0 1v0l3 3v0L0 7v0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 0.1875rem;
  height: 0.375rem;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}
.luf-breadcrumb .luf-breadcrumb--form li {
  margin-right: 0.5rem;
  padding-right: 1.5rem;
}
.luf-breadcrumb .luf-breadcrumb--form li.disabled {
  color: #979797;
}
.luf-breadcrumb .luf-breadcrumb--form li:not(:last-child):after {
  width: 0.5rem;
  height: 1rem;
}

.luf-paraslider {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.luf-paraslider-js {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.luf-paraslider--foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 5;
  background-repeat: no-repeat;
  background-position: 80% 100%;
}
.luf-paraslider .slick-slide {
  position: relative;
  overflow: hidden;
  transition: opacity 3s cubic-bezier(0.47, 0, 0.745, 0.715) !important;
}
.luf-paraslider .slick-list, .luf-paraslider .slick-track {
  height: 100%;
}