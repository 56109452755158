.luf-header {
  position: relative;
  //global styles header

  &--big {
    height: 85vh;
    max-height: rem-calc(700);
  }
  &--home {
    height: 85vh;
    max-height: none;
  }

  .luf-pagetitle {
    position: absolute;
    bottom: rem-calc(43);
    left:0;
    width: 100%;
    text-align: center;
    z-index: 5;

    @include media-breakpoint-up(sm) {
      bottom: rem-calc(47);
    }

    &.luf-handwriting {
      transform: rotate(-2deg);
      transform-origin: left;
      @include fluid-type(font-size, $min-vw, $max-vw, 2.0*$min-font, 6.5*$min-font);
    }
    &.en-title {
      top: 250px;
      @include media-breakpoint-up(sm) {
        top: rem-calc(250);
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & > a {
    color: $body-color;

    &:hover {
      color: $link-color;

      .luf-icon {
        fill: $primary;
      }
    }
  }


  //topnavigation
  & &--top {
    position: relative;
    padding: rem-calc(11) 0;
    z-index: 9;
    background-color: $white;
    //display: none;

    @include media-breakpoint-up(sm) {
      //display: block;
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-up(sm) {

      }
    }

    .luf-header--navitem {
      display: inline-block;
      margin-right: 1.5rem;
      @include media-breakpoint-up(sm) {
        margin-right: rem-calc(27);
      }

      &__text {
        @include luf-hide;

        @include media-breakpoint-up(sm) {
          @include luf-visible;
        }
      }
    }

    .luf-header--nav__top {
      &:nth-child(2) {
        .luf-header--navitem {
          margin-right: rem-calc(13);
        }
      }
    }

    img {
      width: 1.5em;
      height: auto;
    }
  }
  //main
  & &--main {
    position: relative;
  }

  //mainnav
  & &--mainnav {

  }

  //logo und homelink

  //homelink
  &--homelink {
    position: relative;
    z-index: 99;
    display: block;
    width: 60%;

    @include media-breakpoint-up(sm) {
      width: 30%;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    @media only screen
      and (device-width : 414px)
      and (device-height : 896px)
      and (-webkit-device-pixel-ratio : 2) {
        width: 50%;
    }
  }

  //logo
  & &--logo {
    width: 100%;
    padding: 1rem 0;

    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  //search
  .luf-searchbox {
    position: relative;
    z-index: 9;
  }

  & &--nav {

    &:first-child {
      @include media-breakpoint-up(xs) {
        column-count: 2;
      }
      @include media-breakpoint-up(md) {
        column-count: 1;
      }
    }
  }
  & &--navitem {


    .luf-icon {
      width: 1.5em;
      height: 1.1875em;
      &--fb {
        height: 1.5em;
        fill: #046ab0;
      }
    }

  }

  .luf-gradient {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 140px;
      width: 100%;
      background: linear-gradient(180deg,rgba(2,0,36,.4),rgba(0,0,0,0));
      z-index: 1;
    }
  }
}