.luf-paraslider {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &-js {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &--foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 5;
    background-repeat: no-repeat;
    background-position: 80% 100%;
  }

  .slick-slide {
    position: relative;
    overflow: hidden;
    transition: opacity 3s cubic-bezier(0.47, 0, 0.745, 0.715) !important;
  }

  .slick-list, .slick-track {
    height: 100%;
  }
}