$luf-nav-width-mobile: rem-calc(300);
$luf-nav-slideout-mobile: rem-calc(340);
$luf-nav-slideout-big: rem-calc(400);
//menu button mobile
.luf-menubtn {
  display: block;
  position: absolute;
  right: 1rem;
  top: 50%;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  transform: translateY(-50%);
  z-index: 999;
  font-weight: 600;
  cursor: pointer;

  &:focus {
    //outline: 3px solid $primary;
  }

  @include media-breakpoint-up($mainnav_breakpoint) {
    display: none;
  }
}

.luf-navicon {
  position: relative;
  width: rem-calc(30);
  height: rem-calc(20);
  line-height: rem-calc(30);
  vertical-align: middle;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: rem-calc(4);
    width: 100%;
    background: $white;
    border-radius: rem-calc(3);
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2), &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: rem-calc(16);
    }
  }

  &.open {

    span {
      background: $primary;
    }

    span:nth-child(1) {
      top: rem-calc(18);
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: rem-calc(18);
      width: 0%;
      left: 50%;
    }
  }
}

.luf-navtxt {
  @include luf-hide;
}