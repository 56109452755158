.luf-breadcrumb {
  padding: rem-calc(16) 0;
  font-weight: 500;

  & &--menu {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: inline-block;
      margin-right: rem-calc(8);
      padding-right: 1rem;
      line-height: 0.7;

      &:not(:last-child) {
        &:after {
          content: '';
          display: block;
          position: absolute;
          right:0;
          top:0;
          margin-left: 1rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 4 7'%3E%3Cg%3E%3Cg%3E%3Cpath fill='none' stroke='%23979797' stroke-miterlimit='50' d='M0 1v0l3 3v0L0 7v0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          width: rem-calc(3);
          height: rem-calc(6);
          background-repeat: no-repeat;
          background-position: right center;
          background-size: contain;
        }
      }
    }
  }
  & &--form {
    li {
      margin-right: 0.5rem;
      padding-right: 1.5rem;

      &.disabled {
        color: #979797;
      }
      &:not(:last-child) {
        &:after {
          width: rem-calc(8);
          height: rem-calc(16);
        }
      }
    }
  }
}