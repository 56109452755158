.luf-img {
  position: relative;
  overflow: hidden;

  &--holder {
    display: block;
    background-color: $gray-400;
  }

  img {
    position: absolute;
    top:0;
    left:0;
  }

  figure {
    margin: 0;
  }

  &-copyright {
    position: relative;
    padding: rem-calc(4) 0 0 rem-calc(6);
    background-color: $white;
    color: $gray-700;
    font-size: rem-calc(10);
    border-top-left-radius: 4px;
    z-index: 1;
    
    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &.is-small {
        padding: rem-calc(2) 0 0 rem-calc(4);
    }
  }

}

.luf-lazy {

  %lazyshow {
    opacity:0;
    transition: all 500ms $ease-out-alt;
    transition-delay:50ms;
    clip-path: polygon(0% 100%, 0% 0%, 0% 0%, 0% 100%);

    &.loaded {
      opacity:1;
      clip-path: polygon(101% 100%, 101% 0%, 0% 0%, 0% 100%);
    }
  }

  & &--item {
    @extend %lazyshow;

  }

  & &--bg {
    opacity: 0;
    transition: opacity 500ms $ease-out-alt;

    &.loaded {
      opacity: 1;
      height: 100%;
    }
  }

}
