.luf-header {

  .luf-has-menu {
    position: relative;

    & & > a {
      color: $link-color;
      &:focus, &:focus-within {
        & + .luf-submenu {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          pointer-events: auto;
          transition-delay: 0s;
        }
      }
    }

    &:hover{

      & > a {
        //color: $link-color;
      }
      .luf-submenu {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        pointer-events: auto;
        transition-delay: 0s;
      }
    }

    .luf-submenu {
        display: none;
        font-size: 1rem;

         margin-left: 1rem;
         padding-top: 0.5rem;

        @include media-breakpoint-up($mainnav_breakpoint) {
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          height: auto;
          width: auto;
          padding-top: 0;
          margin-left: 0;
          font-size: 1.25rem;
          min-width: rem-calc(240);
          box-shadow: 0 10px 80px rgba(0, 0, 0, 0.3);
          z-index: 254;

          opacity: 0;
          visibility: hidden;
          pointer-events: none;

          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
          transition-delay: 0s;
          transform: translateY(4rem);
        }

      &--indicator {

        @include media-breakpoint-up($mainnav_breakpoint) {
          position: absolute;
          top:0;
          left: 2rem;
          transform: translateY(-100%);
          @include triangle-up(1.25rem, $white);
        }
      }

        li {
          position: relative;
          background-color: white;
          padding: rem-calc(8);
          width: 100%;

          @include media-breakpoint-up($mainnav_breakpoint) {
            padding: rem-calc(23) rem-calc(23);
            width: 120%;
          }

          &.active {
            a {
              color: $primary;
            }
          }
        }
        .sub-menu {
          left: 100%;
          z-index: 9;
        }
    } //submenu
  }

} //luf header