.luf-mainnav {

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0.3rem;

  @include media-breakpoint-up($mainnav_breakpoint) {
    height: auto;
    overflow: visible;
    padding-left: 0;
  }

  &--wrapper {
    position: fixed;
    width: 90%;
    height: 100vh;
    top:0;
    right:0;
    z-index: 99;
    background-color: $white;
    padding: rem-calc($grid-gutter-width * 2);
    padding-right: 1rem;
    padding-top: 7rem;

    transform: translateX(100%);
    transition-duration: 0.5s;
    transition-timing-function: $ease-in-out;

    &.open {
      transform: translateX(0%);
    }

    @include media-breakpoint-up($mainnav_breakpoint) {
      position: static;
      width: auto;
      height: auto;
      top:auto;
      height: auto;
      background-color: transparent;
      padding: 0;
      transform: none;
    }
  }



  &--item {
    position: relative;
    padding: rem-calc(15) 0;

    @include media-breakpoint-up($mainnav_breakpoint) {
      position: static;
      display: inline-block;
      padding: rem-calc(29) 0;
      margin-right: rem-calc(20);
    }

    @include media-breakpoint-up(md) {
      margin-right: rem-calc(41);
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      & > a {
        box-shadow: 0 3px 0 $white;
      }
    }
    & > a {

      @include media-breakpoint-up($mainnav_breakpoint) {
        color: $white;
      }

      &:hover {
        //color: $link-color;
      }
    }

    //item indicator
    &__indicator {
      position: absolute;
      right: 2rem;
      top: 1.5rem;
      height: 1.2rem;
      width: 1.2rem;
      overflow: hidden;
      border: none;
      background-color: transparent;
      box-shadow: none;

      @include media-breakpoint-up($mainnav_breakpoint) {
        display: none;
        pointer-events: none;
      }
    }
    //item arrow
    &__arrow {
      @include arrow($size: 1rem, $color: $primary, $angle: 45, $thick: 3, $direction: 0, $bright: 20%, $aleft: 20%);
      top: 25%;

      &:before,&:after {
        transition-duration: 0.3s;
      }
    }
  }
}