.luf-header {

  .luf-has-megamenu {

    & & > a {
      &:focus, &:focus-within {
        //color: $link-color;

        & + .luf-megamenu {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          pointer-events: auto;
          transition-delay: 0s;
        }
      }
    }
    &:hover {

      & > a {
        //color: $link-color;
      }

      & > .luf-megamenu {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        pointer-events: auto;
        transition-delay: 0s;
      }
    }

    .luf-megamenu {
      display: none;
      padding-top: 1rem;

      @include media-breakpoint-up($mainnav_breakpoint) {
        display: flex;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        padding: rem-calc(40);
        background: $white;
        box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
        z-index: 254;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        transition-delay: 0s;
        transform: translateY(4rem);
      }


    &--indicator {

      @include media-breakpoint-up($mainnav_breakpoint) {
        position: absolute;
        top:0;
        left: 50%;
        transform: translateY(-100%);
        @include triangle-up(1.25rem, $white);
      }
    }

      p {
        margin-bottom: 0.625rem;
      }

      &--card {
        padding-right: rem-calc(20) !important;
        margin-bottom: 2rem;

        @include media-breakpoint-up($mainnav_breakpoint) {
          margin-bottom: 3.75rem;
          padding-right: rem-calc(40) !important;
        }

        a {
          display: block;
          color: $body-color;
        }
        p {
          line-height: 1.75;
        }
        &__header {
          position: relative;
          margin-bottom: $grid-gutter-width / 2;

          &--info {
            position: absolute;
            bottom:0;
            left: 0;
            margin: 0;

            .luf-tag--category {
              font-size: rem-calc(12);
              margin: 0;
              padding: rem-calc(9) rem-calc(10);

              @include media-breakpoint-up($mainnav_breakpoint) {
                font-size: 1rem;
              }
            }
          }
        }
        &__cta {
          color: $primary;
        }
      }
      &--sideitems {

          @include media-breakpoint-up($mainnav_breakpoint) {
            height: 100%;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid rgba($body-color, 0.2);
            padding-top: $grid-gutter-width;
          }

          @include media-breakpoint-up(lg) {
            padding-bottom: 3.75rem;
            padding-top: 0;
            border-top:0;
            border-left: 1px solid rgba($body-color, 0.2);
            flex-flow: column;
          }
      }
      &--sideitem {
        padding-bottom: 2rem;

        @include media-breakpoint-up($mainnav_breakpoint) {
          padding-right: $grid-gutter-width;
        }

        @include media-breakpoint-up(lg) {
          padding-left: $grid-gutter-width;
          padding-right: 0;

        }

        &:last-child {
          padding-bottom: 0;

          @include media-breakpoint-up($mainnav_breakpoint) {

          }

          @include media-breakpoint-up(lg) {
            padding-top: 3rem;
            border-top: 1px solid rgba($body-color, 0.2);
          }

          .luf-icon {
            @include media-breakpoint-up(lg) {
              font-size: rem-calc(45);
            }
          }
        }

        &--header {
          display: flex;
          align-items: center;
        }

        &--title {
          //display: inline-block;
          margin: 0;
        }

        .luf-icon {
          font-size: rem-calc(32);
          margin-right: 1rem;
        }

      }
    }
  }
}