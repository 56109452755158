.luf {
  &-europe {
    .luf-searchbox--wrapper {
      background-color: $ct-1;

      .btn-primary {
        background-color: darken($ct-1,20%);
        border-color: darken($ct-1,20%);
      }
    }
    .luf-tag--category {
      background-color: $ct-1;
    }
    .luf-link {
      box-shadow: 0 2px 0 $ct-1;
    }
  }
  &-active {
    .luf-searchbox--wrapper {
      background-color: $ct-2;

      .btn-primary {
        background-color: darken($ct-2,20%);
        border-color: darken($ct-2,20%);
      }
    }
    .luf-tag--category {
      background-color: $ct-2;
    }
    .luf-link {
      box-shadow: 0 2px 0 $ct-2;
    }
  }
  &-music {
    .luf-searchbox--wrapper {
      background-color: $ct-3;

      .btn-primary {
        background-color: darken($ct-3,20%);
        border-color: darken($ct-3,20%);
      }
    }
    .luf-tag--category {
      background-color: $ct-3;
      color: $black;
    }
    .luf-link {
      box-shadow: 0 2px 0 $ct-3;
    }
  }
  &-hiking {
    .luf-searchbox--wrapper {
      background-color: $ct-4;

      .btn-primary {
        background-color: darken($ct-4,20%);
        border-color: darken($ct-4,20%);
      }
    }
    .luf-tag--category {
      background-color: $ct-4;
    }
    .luf-link {
      box-shadow: 0 2px 0 $ct-4;
    }
  }
  &-cycling{
    .luf-searchbox--wrapper {
      background-color: $ct-5;

      .btn-primary {
        background-color: darken($ct-5,20%);
        border-color: darken($ct-5,20%);
      }
    }
    .luf-tag--category {
      background-color: $ct-5;
    }
    .luf-link {
      box-shadow: 0 2px 0 $ct-5;
    }
  }
  &-day{
    .luf-searchbox--wrapper {
      background-color: $ct-6;

      .btn-primary {
        background-color: darken($ct-6,20%);
        border-color: darken($ct-6,20%);
      }
    }
    .luf-tag--category {
      background-color: $ct-6;
    }
    .luf-link {
      box-shadow: 0 2px 0 $ct-6;
    }
  }
}



