.luf-floatlabel {

  position: relative;

  &.is-focused, &.has-value {
    .luf-floatlabel--label {
      left: 0.3rem;
      transform: translateY(-100%) scale(0.6);
    }
  }

  &--label {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 1.4375rem;
    font-size: rem-calc(14);
    line-height: 1.5;
    color: $gray-500;
    transform-origin: 0 0;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transform: translateY(-50%);
  }
}