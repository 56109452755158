/**************************************
*backgrounds
*************************************/

//responsive backgroud image class
.luf-bg--resp {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

//background position
.luf-bg--abs {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}

.luf-bg--bgc {
  background-color: $gray-400;
}

