.luf-searchbox {

  &.open {

  }

  input, textarea, select {
    background-color: $white;
    border-color: $white;
  }

  select {
    min-width: 145px;
    padding-right: 10px !important;
    @include media-breakpoint-up(lg) {
      min-width: 0px;
      padding-right: 2rem !important;
    }
  }

  &--wrapper {
    position: relative;
    background-color: $primary;
    padding: rem-calc(20);
  }

  .form-group {
    margin-right: rem-calc(4);
    margin-bottom: 0;
    flex-grow: 1;
    flex-basis: 0;

    &:last-child {
      flex-grow: 0;
    }

    .luf-icon__inline {
      margin-right: 0.2em;
      fill: currentColor;
    }
  }
  .form-control {
    padding: .5625rem 0.6375rem;
    height: rem-calc(50) !important;

    @include media-breakpoint-up(md) {
      padding: .5625rem 1rem;
    }
  }

  .luf-floatlabel--label {
    left: 0.6375rem;

    @include media-breakpoint-up(md) {
      left: 1rem;
    }
  }

  & &--title {
    position: relative;
    display: inline-block;
    padding-right: rem-calc(25);
    color: $white;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    margin-bottom: 0;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      line-height: rem-calc(50);
      float: left;
      max-width: 20%;
      cursor: auto;
      pointer-events: none;
    }

    .luf-icon {
      fill: $white;
      margin-right: 0.1em;
      @include media-breakpoint-up(md) {
        width: rem-calc(30);
        height: rem-calc(30);
        margin-right: 0.5em;
      }
    }
  }

  & &--status {
    position: absolute;
    top: 0.3rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;

    @include media-breakpoint-up(md) {
      display: none;
    }
    &__indicator {
      @include arrow($size: 1rem, $color: $white, $angle: 45, $thick: 2, $direction: 0, $bright: 21%, $aleft: 22%);
      //transform: rotate(0deg) translateX(0%) translateY(-50%);
      transform-origin: center;
      top: 0%;

      &:before, &:after {
        transition-duration: 0.3s;
      }
    }
  }

  & &--content {

    width: 100%;
    display: none;
    padding-top: 2rem;

    @include media-breakpoint-up(md) {
      display: block !important;
      float: left;
      width: 80%;
      padding-top: 0;
    }

    &__inside {
      display: flex;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
      }

      .form-group {
        flex: 1 1 100%;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
          flex: 1 1 auto;
          margin-bottom: 0;
        }
      }
    }
  }

  .btn-submit {
    display: block;
    background-color: $ct-1;
    border-color: $ct-1;
    font-size: rem-calc(20);
    line-height: 33px;

    &:focus {
      outline: 1px solid darken($ct-1, 50%);
    }
  }
}