.icon-flipped {
  display: inline-block;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.luf-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: $black;
  fill-opacity: 1;
  vertical-align: text-top;
  transition-duration: 0.3s;
  margin-right: 0.25em;

  &__inline {
    margin-right: 0.4em;
  }
}


